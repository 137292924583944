import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tooltip,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import NoImage from "../../../../assets/no-image.jpg";
import BG from "../../../../assets/RewardAssets/bg.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosRequestAuth, Base_Url } from "../../../api/api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/Snackbar/SnackbarReducder";

interface Attribute {
  trait_type: string;
  description: string;
  value: string;
}

interface Content {
  attributes: Attribute[];
  collection: {
    name: string;
    family: string;
  };
  name: string;
  NFTokenTaxon: number;
  description: string;
  schema: string;
  image?: string;
  video?: string;
  nftType: string;
}

interface NFT {
  Flags: number;
  Issuer: string;
  NFTokenID: string;
  NFTokenTaxon: number;
  URI: string;
  nft_serial: number;
  content: Content;
  isClaim: boolean;
}

interface NFTCardProps {
  nft: NFT;
  transferNft: (nftId: string) => void;
}

const NFTCard: React.FC<NFTCardProps> = ({ nft, transferNft }) => {
  const renderMedia = () => {
    if (nft.content.image) {
      return (
        <img
          src={nft.content?.image}
          alt={nft.content?.name}
          style={{
            width: "200px",
            height: "200px",
            borderRadius: 6,
            objectFit: "cover",
          }}
        />
      );
    } else if (nft.content.video) {
      return (
        <video
          loop
          autoPlay
          muted
          style={{
            width: "200px",
            height: "200px",
            borderRadius: 6,
            objectFit: "cover",
          }}
        >
          <source src={nft.content.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return null;
    }
  };

  return (
    <Card
      className="animate__animated animate__backInUp"
      sx={{
        borderRadius: "8px",
        backgroundColor: "rgb(0,0,0,0.6)",
        pt: 1,
        px: 1,
        width: "max-content",
      }}
    >
      {renderMedia()}
      <CardContent sx={{ p: 0, m: 0, mb: -2 }}>
        <Typography
          fontFamily={"Jedi"}
          sx={{
            fontWeight: "bold",
            color: "white",
            whiteSpace: "nowrap",
          }}
          variant="h6"
          component="div"
        >
          <Tooltip title={nft.content?.name}>
            <span>
              {nft.content?.name
                ? nft.content?.name.length > 21
                  ? `${nft.content?.name.slice(0, 18)}...`
                  : nft.content?.name
                : "n/a"}
            </span>
          </Tooltip>
        </Typography>
        <Typography
          fontFamily={"Jedi"}
          fontWeight={"bold"}
          variant="body2"
          color="rgba(109, 258, 264, 0.6)"
        >
          {nft.content.collection?.name}
        </Typography>
        <Typography
          fontFamily={"Jedi"}
          fontWeight={"bold"}
          variant="body2"
          color="rgba(109, 258, 264, 0.6)"
        >
          {nft.content.collection?.family}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => {
              if (!nft.isClaim) transferNft(nft.NFTokenID);
            }}
            size="small"
            fullWidth
            sx={{
              p: 1,
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: 0.8,
              postion: "absolute",
              cursor: nft.isClaim ? "" : "pointer",
              mt: "10px",
              color: "white",
              background: nft.isClaim ? "green" : "rgba(109, 258, 264, 0.6)",
              border: "1px solid #6DF8FE",
              "&:hover": {
                background: nft.isClaim ? "green" : "#1b1b1b35",
                color: nft.isClaim ? "white" : "white",
              },
            }}
          >
            {nft.isClaim ? "Claimed" : "Claim NFT"}
          </Button>
        </Box>
        {/* <Button variant="contained">Claim Reward</Button> */}
      </CardContent>
    </Card>
  );
};

const RewardNftList: React.FC<{ data?: NFT[]; backgroundLink: string }> = ({
  data,
  backgroundLink,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentRouteName = location.pathname;
  const [nftData, setNftData] = useState<NFT[]>(data || []);
  const backgroundUrl = currentRouteName.includes("rewardbag")
    ? Base_Url + `/rewardbagBackground/${backgroundLink}`
    : Base_Url + `/${backgroundLink}`;
  const [loading, setLoading] = useState<boolean>(false);
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");

  const transferNft = async (nftId: string) => {
    toast("Check your xumm wallet.", { autoClose: 3000 });
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "post",
        `api/luxlion/rewardbag/transfernft`,
        { NFTokenID: nftId },
        {}
      );
      console.log(response);
      if (response?.data?.success === false && response?.status) {
        if (mobileViewCheck)
          toast.error("Claim NFT Failed.", { autoClose: 3000 });
        else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: `Claim NFT Failed.`,
            })
          );
        }
        setLoading(false);
      } else if (response?.data?.success === undefined && response?.status) {
        if (mobileViewCheck)
          toast.success("NFT reward claimed successfully.", {
            autoClose: 3000,
          });
        else {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: `NFT reward claimed successfully.`,
            })
          );
        }
        setNftData(
          nftData.map((item) =>
            item.NFTokenID === nftId ? { ...item, isClaim: true } : item
          )
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (mobileViewCheck) toast.error("Request failed.", { autoClose: 3000 });
      else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: `Claim NFT Failed.`,
          })
        );
      }
      console.error(error);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "absolute",
        zIndex: "100",
        width: "100vw",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        overflowY: "auto",

        //backgroundPosition: "center",
        //backgroundRepeat: "no-repeat",
        //background: "linear-gradient(to right, #000428, #004e92)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
          mb: 4,
        }}
      >
        <Box>
          <Button
            onClick={() => {
              if (currentRouteName.includes("rewardbag"))
                navigate(`/campaigns/rewardbag`);
              else navigate(`/campaigns`);
            }}
          >
            <ArrowBackIcon sx={{ color: "white" }} />
          </Button>
        </Box>
        <Box>
          <Typography
            variant="h4"
            sx={{
              backgroundColor: "rgb(0,0,0,0.2)",
              width: "fit-content",
              color: "white",
              marginInlineStart: mobileViewCheck ? 12 : 0,
            }}
          >
            REWARDS
          </Typography>
        </Box>
        {!mobileViewCheck && <Box></Box>}
        {!mobileViewCheck && <Box></Box>}
        {mobileViewCheck && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mr: 1,
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/reward`);
              }}
              size="small"
              sx={{
                p: 1,
                fontSize: "14px",
                postion: "absolute",
                mt: "10px",
                color: "white",
                background: "#1b1b1b35",
                mb: "10px",
                border: "1px solid #6DF8FE",
                "&:hover": {
                  background: "rgba(109, 258, 264, 0.6)",
                  color: "black",
                },
              }}
            >
              Claim Rewards
            </Button>
          </Box>
        )}
      </Box>

      {!mobileViewCheck && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/reward`);
            }}
            size="small"
            sx={{
              p: 1,
              fontSize: "14px",
              postion: "absolute",
              mt: "10px",
              color: "white",
              background: "#1b1b1b35",
              mb: "10px",
              border: "1px solid #6DF8FE",
              "&:hover": {
                background: "rgba(109, 258, 264, 0.6)",
                color: "black",
              },
            }}
          >
            Claim Rewards
          </Button>
        </Box>
      )}

      <Grid
        container
        spacing={10}
        sx={{
          display: "flex",
          flexDirection: mobileViewCheck ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Box
            height={"95vh"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress size={70} sx={{ color: "white" }} />
          </Box>
        ) : (
          <>
            {nftData &&
              nftData.map((nft) => (
                <Grid
                  item
                  key={nft.NFTokenID}
                  xs={12}
                  sm={6}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <NFTCard nft={nft} transferNft={transferNft} />
                </Grid>
              ))}
          </>
        )}
      </Grid>
    </div>
  );
};

export default RewardNftList;
