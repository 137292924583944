import "./Card.modules.css";
import "./Cards.css";
import { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { luxLionContentsI } from "../../../../types/nftTypes";
import { convertIPFSLink } from "../../../HelperFunctions/HelperFunctions";
import { useSelector } from "react-redux";
import noImage from "../../../../assets/no-image.jpg";

interface luxLionCardPropsI {
  imagen: string;
  info: luxLionContentsI;
}
function LuxLionCard({ imagen, info }: luxLionCardPropsI) {
  const [show, setShown] = useState(false);
  const bigScreenCheck = useMediaQuery("(max-width:1725px)");

  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  const props3 = useSpring({
    opacity: 1,
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 160px rgb(0 0 0 / 100%)",
  });

  return (
    // <Box sx={{ border: "solid 4px red" }}>
    <animated.div
      className={
        campaignsSelector?.campaignType === "2" && bigScreenCheck
          ? "cardCampType2"
          : bigScreenCheck
          ? "card"
          : "card-big"
      }
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      {bigScreenCheck ? (
        <>
          <Box
            maxHeight={250}
            sx={{
              // background: "red",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              mt: 1,
            }}
          >
            {imagen && imagen ? (
              <img
                style={{ marginTop: 10 }}
                src={convertIPFSLink(imagen)}
                alt={info?.name}
                crossOrigin="anonymous"
                loading="lazy"
              />
            ) : (
              <img
                style={{ marginTop: 10 }}
                src={noImage}
                alt={""}
                crossOrigin="anonymous"
                loading="lazy"
              />
            )}
          </Box>
          <Typography sx={{ marginTop: 1, fontSize: 18 }} variant="h5">
            {info?.name}
          </Typography>
        </>
      ) : (
        <>
          <Box
            maxHeight={250}
            sx={{
              // background: "red",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              mt: 1,
            }}
          >
            {imagen && imagen ? (
              <img
                loading="lazy"
                style={{ marginTop: 10 }}
                src={imagen}
                alt={info?.name}
                crossOrigin="anonymous"
              />
            ) : (
              <img
                style={{ marginTop: 10 }}
                src={noImage}
                alt={""}
                crossOrigin="anonymous"
                loading="lazy"
              />
            )}
          </Box>
          <Typography sx={{ marginTop: 3, fontSize: 18 }} variant="h5">
            {info?.name}
          </Typography>
        </>
      )}
    </animated.div>
    // </Box>
  );
}

export default LuxLionCard;
