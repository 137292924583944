import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";

interface Column {
  label: string;
  key: string;
  dataType?: any;
}

interface CustomTableProps {
  data: any[];
  columns: Column[];

  editable?: boolean;
  actions?: boolean;
  onDeleteClick?: (item: any) => void;
  showDeleteIcon?: boolean;
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  columns,
  actions,
  onDeleteClick,
  showDeleteIcon,
}) => {
  const cellStyleHeader: React.CSSProperties = {
    textAlign: "left",
    color: "#2e2b2b",
    fontWeight: "bold",
    fontSize: 16,
    padding: "2px 2px",
    height: "20px",
  };

  const cellStyle: React.CSSProperties = {
    textAlign: "left",
    color: "#2e2b2b",
    padding: "2px 2px",
    height: "40px",
    maxWidth: "100px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <>
      <TableContainer
        sx={{
          background: "#ffffff",
          // border: "1px solid #a7a7a7",
          borderRadius: "10px",
          maxHeight: "70vh",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {!data.length
                ? ""
                : columns.map((column, index) => (
                    <TableCell key={index} sx={cellStyleHeader}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 600, fontSize: 16 }}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
              {!data.length ? (
                ""
              ) : actions === true ? (
                <TableCell sx={cellStyleHeader}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600, fontSize: 18 }}
                  >
                    Action
                  </Typography>
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length ? (
              <Box display="flex" justifyContent={"center"} p={4}>
                <Typography variant="h6" component="h2">
                  No data found!
                </Typography>
              </Box>
            ) : (
              data.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    background: "transparent",
                    "&:hover": { background: "#e8e5e5" },
                  }}
                >
                  {columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex} sx={cellStyle}>
                      <Tooltip
                        title={String(item[column.key])}
                        style={{ cursor: "default" }}
                      >
                        <span>
                          {column.dataType === "number"
                            ? Number(item[column.key]).toLocaleString()
                            : column.dataType === "date"
                            ? format(new Date(item[column.key]), "MM/dd/yy`yy")
                            : item[column.key] || "NA"}
                        </span>
                      </Tooltip>
                    </TableCell>
                  ))}

                  {actions === true ? (
                    <TableCell sx={cellStyle}>
                      {!showDeleteIcon && (
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            border: "none",
                            color: "#000000",
                            p: 0,
                            m: 0,
                          }}
                          onClick={() => onDeleteClick && onDeleteClick(item)}
                        >
                          <DeleteIcon sx={{ p: 0, m: 0 }} />
                        </Button>
                      )}
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTable;
