import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router";

import LogoutComp from "../../Components/Logout/Logout";
import Logo from "../../assets/BreedingAssets/logo.png";
import BreedingBackground from "../../assets/BreedingAssets/BreedingBackground.png";

import RefreshIcon from "@mui/icons-material/Refresh";
import useMediaQuery from "@mui/material/useMediaQuery";
import styleGrids from "./DesignGrids.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  API,
  Base_Url,
  axiosRequestAuth,
  axiosRequestEmail,
} from "../../Components/api/api";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { setSnackbar } from "../../redux/Snackbar/SnackbarReducder";

import moment from "moment";
import TabPanel from "../../Components/TabPanels/TabPanels";

import {
  Box,
  Button,
  Tabs,
  Tab,
  Grid,
  Paper,
  Typography,
  Chip,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Divider,
  Modal,
} from "@mui/material";
import { setPotionId } from "../../redux/PotionReducer/PotionReducer";
import { IsAny } from "@reduxjs/toolkit/dist/tsHelpers";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 800,
  bgcolor: "rgba(109, 220, 254,1)",

  boxShadow: 24,
  p: 4,
};

const historyURL = "api/luxlion/child-nfts";

const token: any = localStorage.getItem("token");

// using for lux lab version 2
const Transactions = () => {
  const smallScreenCheck = useMediaQuery("(min-width:450px)");
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [historyState, setHistoryState] = useState<any>([]);
  const [contact, setContact] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const hash = useSelector((state: any) => state.queue.txnHash);
  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  const backgroundUrl = Base_Url + `/${campaignsSelector?.background}`;
  const logoUrl = Base_Url + `/${campaignsSelector?.logo}`;

  const processQueueNum = useSelector(
    (state: any) => state.queue.currentProcessNumber
  );
  const yourProcessNum = useSelector(
    (state: any) => state.queue.yourProcessNumber
  );
  const loaderredux = useSelector((state: any) => state.queue.loader);

  const [loaderStateHistory, setLoaderStateHistory] = useState<boolean>(false);
  const [nftHash, setNftHash] = useState<any>("");
  const [queueStates, setQueueStates] = useState<any>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [loaderState, setLoaderState] = useState<boolean>(false);
  const [queuecurrently, setQueuecurrently] = useState<any>("");
  const [yournum, setYournum] = useState<any>("");

  const handleContactInfo = (e: any) => {
    setContact(e.target.value);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    setNftHash(hash);
    setLoaderState(loaderredux);

    let currentlyProcessingVal;

    if (typeof processQueueNum === "number")
      currentlyProcessingVal = processQueueNum;
    else if (
      typeof processQueueNum === "object" &&
      processQueueNum !== null &&
      "currentlyProcessing" in processQueueNum
    )
      currentlyProcessingVal = processQueueNum.currentlyProcessing;

    if (processQueueNum) setQueuecurrently(currentlyProcessingVal);
    setYournum(yourProcessNum);
  }, [hash, processQueueNum, loaderredux, yourProcessNum]);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 1) {
      getHistory();
    }
  };

  const handleMessageChange = (e: any) => {
    setMessage(e.target.value);
  };

  async function getHistory() {
    setLoaderStateHistory(true);
    const accnum = localStorage.getItem("accnum");
    try {
      const response: any = await axiosRequestAuth("post", historyURL, {
        userAccount: accnum,
      });
      setHistoryState(response?.data?.childNfts);
      setLoaderStateHistory(false);
    } catch (err: any) {
      setLoaderStateHistory(false);
      console.log("data is Error", err?.response?.data?.message);
    }
  }

  async function sendEmail() {
    setLoading(true);
    let user = localStorage.getItem("accnum");
    try {
      setMessage("");
      setContact("");
      setOpenModal(false);
      const response: any = await axiosRequestEmail(
        "post",
        "api/luxlion/support",
        {
          user_email: contact,
          user_account: user,
          user_query: message,
        }
      );
      setLoading(false);

      // setLoaderStateHistory(false);
      if (response.data.status === true) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Email sent, we will contact you shortly",
          })
        );
      }
    } catch (err: any) {
      setLoading(false);

      // setLoaderStateHistory(false);
      console.log("data is Error", err?.response?.data?.message);
    }
  }

  async function getQueueStatus() {
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "post",
        "api/luxlion/queue",
        {
          // userAccount: accnum,
        }
      );
      setLoading(false);

      setQueueStates(response.data);
      // setHistoryState(response?.data?.childNfts);
      // setLoaderStateHistory(false);
      if (response.data.user_queue_status === "Failed") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              "Something went wrong, please contact support@luxlionsnft.com",
          })
        );
      }
    } catch (err: any) {
      setLoading(false);

      // setLoaderStateHistory(false);
      console.log("data is Error", err?.response?.data?.message);
    }
  }
  useEffect(() => {
    getHistory();
    getQueueStatus();
    // dispatch(
    //   setSnackbar({
    //     snackbarOpen: true,
    //     snackbarType: "success",
    //     snackbarMessage: "In mobile",
    //   })
    // );
  }, []);

  return (
    <>
      <div>
        {campaignsSelector != null ? (
          <img
            src={backgroundUrl}
            alt=""
            style={{ width: "100%", height: "100vh" }}
          />
        ) : (
          <img
            src={BreedingBackground}
            alt=""
            style={{ width: "100%", height: "100vh" }}
          />
        )}

        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <>
            <Grid container spacing={2}>
              <Grid
                item
                sm={3}
                lg={3}
                md={3}
                xs={12}
                sx={{ paddingTop: 4, display: "flex" }}
                justifyContent="center"
              >
                <Box sx={{ maxWidth: 150, paddingTop: 3 }}>
                  {campaignsSelector != null ? (
                    <img
                      src={`${logoUrl}`}
                      alt=""
                      width="100%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/breeding/${campaignsSelector.campaign_id}`);
                      }}
                    />
                  ) : (
                    <img
                      src={`${Logo}`}
                      alt=""
                      width="100%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/campaigns`);
                      }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sm={6}
                sx={{ ml: 0 }}
                justifyContent="right"
              >
                <Box
                  width="100%"
                  borderRadius="8px"
                  sx={{
                    border: "1px solid #6DF8FE",
                    mt: 2,
                    height: "6vh",
                    background: "rgba(70, 70, 70, 0.8)",
                  }}
                >
                  <Box
                    width="80%"
                    borderRadius="8px"
                    sx={{
                      paddingInline: 2,
                      pt: 1,
                      background: "rgba(70, 70, 70, 0.8);",
                      display: "flex",
                      justifyContent: "center",
                      ml: 6,
                      mb: 2,
                      color: "white",
                      zIndex: 99,
                      position: "relative",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      Transaction History
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                sm={3}
                sx={{ mt: 3, display: "flex" }}
                justifyContent="center"
              >
                <Box sx={{ textAlign: "right" }}>
                  <LogoutComp />
                </Box>
              </Grid>
            </Grid>
            {smallScreenCheck ? (
              <Box sx={{ paddingInline: 12, pt: 1 }}>
                <Box
                  sx={{
                    width: "100%",
                    background: "rgba(70, 70, 70, 0.8);",
                    borderRadius: 5,
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "rgba(109, 248, 254, 0.4)",
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      fontWeight: 600,
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{
                        sx: { backgroundColor: "#6DF8FE" },
                      }}
                      sx={{
                        "& button.Mui-selected": { color: "#6DF8FE" },
                      }}
                    >
                      <Tab
                        label="Current Process"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                      />
                      <Tab
                        label="History"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                        onClick={() => getHistory}
                      />
                    </Tabs>
                  </Box>

                  <TabPanel value={tabValue} index={0}>
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      <Box
                        minHeight={55}
                        maxHeight={55}
                        minWidth={"100%"}
                        sx={{
                          background: " rgba(109, 248, 254, 0.7)",
                          display: "flex",
                          direction: "row",
                          borderRadius: 2,
                          color: "white",
                          mt: 1,
                        }}
                      >
                        <Grid
                          item
                          sm={2.5}
                          display="flex"
                          justifyContent={"center"}
                        >
                          {!loading ? (
                            queueStates?.user_queue_status !== "Success" ? (
                              <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                                Current queue number:{" "}
                                {queueStates?.current_queue_number}
                              </Typography>
                            ) : (
                              <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                                Your task is done!
                              </Typography>
                            )
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          sm={7}
                          display="flex"
                          justifyContent={"center"}
                        >
                          {!loading ? (
                            queueStates?.user_queue_status === "Success" ? (
                              <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                                Your task is done!
                              </Typography>
                            ) : queueStates?.user_queue_status === "Failed" ? (
                              <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                                Task failed, click here for support{" "}
                                <Button
                                  onClick={handleOpenModal}
                                  sx={{
                                    background: "#000080",
                                    color: "white",

                                    "&:hover": {
                                      backgroundColor: "#0808bc",
                                      color: "f1f1f1",
                                      "& .MuiSvgIcon-root": {
                                        color: "black", // Change the icon color on hover
                                      },
                                    },
                                    "&:active": {
                                      backgroundColor: "#1254e3",
                                      color: "f1f1f1",
                                      "& .MuiSvgIcon-root": {
                                        color: "black", // Change the icon color on click
                                      },
                                    },
                                  }}
                                >
                                  {
                                    <SupportAgentIcon
                                      sx={{
                                        color: "white !important",
                                      }}
                                    />
                                  }
                                </Button>
                              </Typography>
                            ) : (
                              <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                                {`Your queue number is :${queueStates?.user_queue_number}`}
                              </Typography>
                            )
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          display="flex"
                          justifyContent="right"
                          px={2}
                          py={1}
                        >
                          <Box>
                            <Button
                              variant="contained"
                              size={"small"}
                              sx={{
                                background: "#000080",
                                color: "white",

                                "&:hover": {
                                  backgroundColor: "#0808bc",
                                  color: "f1f1f1",
                                  "& .MuiSvgIcon-root": {
                                    color: "black", // Change the icon color on hover
                                  },
                                },
                                "&:active": {
                                  backgroundColor: "#1254e3",
                                  color: "f1f1f1",
                                  "& .MuiSvgIcon-root": {
                                    color: "black", // Change the icon color on click
                                  },
                                },
                              }}
                              onClick={getQueueStatus}
                            >
                              {loading ? (
                                <CircularProgress
                                  size={25}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                <Typography
                                  sx={{
                                    fontFamily: "Jedi",
                                    display: "flex",
                                    direction: "row",
                                  }}
                                >
                                  Refresh{" "}
                                  {
                                    <RefreshIcon
                                      sx={{
                                        color: "white !important",
                                      }}
                                    />
                                  }
                                </Typography>
                              )}
                            </Button>
                          </Box>
                        </Grid>
                      </Box>

                      <Divider />
                    </Grid>
                  </TabPanel>
                  {/*////////////////////////////////// History ////////////////////////////////////////////// */}
                  <TabPanel value={tabValue} index={1}>
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      {!loaderStateHistory ? (
                        historyState?.length ? (
                          historyState.map((data: any) => (
                            <Box
                              minHeight={55}
                              maxHeight={55}
                              minWidth={"100%"}
                              sx={{
                                background: " rgba(109, 248, 254, 0.7)",
                                display: "flex",
                                direction: "row",
                                borderRadius: 2,
                                color: "white",
                                mt: 1,
                              }}
                            >
                              <Grid
                                item
                                sm={2.5}
                                display="flex"
                                justifyContent={"center"}
                              >
                                <Typography sx={{ p: 1, fontFamily: "Jedi" }}>
                                  {moment(data.bornAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={7}
                                display="flex"
                                justifyContent={"center"}
                              >
                                <Typography
                                  sx={{
                                    p: 1,
                                    fontFamily: "Jedi",
                                    fontWeight: 600,
                                  }}
                                  variant="body1"
                                >
                                  Hash# {data.txnHash}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={2}
                                display="flex"
                                justifyContent="right"
                                px={2}
                                py={1}
                              >
                                <Box>
                                  <Button
                                    sx={{
                                      color: "white",
                                      background: "green",
                                      fontSize: 18,
                                      borderRadius: 10,
                                      mr: 0,
                                      // mt: -1,
                                      border: "1px solid #ffff",
                                      "&:hover": {
                                        background: "rgba(109, 258, 264, 0.6)",
                                        color: "black",
                                      },
                                    }}
                                    href={`${API.ledger}/transactions/${data?.txnHash}`}
                                    target={"_blanK"}
                                  >
                                    <Typography
                                      sx={{ fontFamily: "Jedi", fontSize: 14 }}
                                    >
                                      {"Explorer "}
                                    </Typography>

                                    <OpenInNewIcon fontSize="small" />
                                  </Button>
                                </Box>
                              </Grid>
                            </Box>
                          ))
                        ) : (
                          <>
                            <Grid
                              item
                              sm={12}
                              display="flex"
                              justifyContent={"center"}
                            >
                              <Typography variant="h5" sx={{ color: "white" }}>
                                {"No history found"}
                              </Typography>
                            </Grid>
                          </>
                        )
                      ) : (
                        <Grid
                          item
                          sm={12}
                          display="flex"
                          justifyContent={"center"}
                        >
                          <CircularProgress sx={{ color: "white" }} />
                        </Grid>
                      )}
                    </Grid>
                  </TabPanel>
                </Box>
              </Box>
            ) : (
              /////////////////////////// Mobile View  //////////////////////////////////
              <Box sx={{ paddingInline: 2, pt: 1 }}>
                <Box
                  sx={{
                    width: "100%",
                    background: "rgba(70, 70, 70, 0.8);",
                    borderRadius: 5,
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      backgroundColor: "rgba(109, 248, 254, 0.4)",
                      borderTopRightRadius: 5,
                      borderTopLeftRadius: 5,
                      fontWeight: 600,
                    }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{
                        sx: { backgroundColor: "#6DF8FE" },
                      }}
                      sx={{
                        "& button.Mui-selected": { color: "#6DF8FE" },
                      }}
                    >
                      <Tab
                        label="Current Process"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                      />
                      <Tab
                        label="History"
                        sx={{ fontWeight: 600, fontFamily: "jedi" }}
                        onClick={() => getHistory()}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={tabValue} index={0}>
                    {" "}
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      <Box
                        minHeight={10}
                        maxHeight={70}
                        minWidth={"100%"}
                        sx={{
                          background: " rgba(109, 248, 254, 0.7)",
                          display: "flex",
                          direction: "row",
                          borderRadius: 2,
                          color: "white",
                          mt: 1,
                        }}
                      >
                        <Grid
                          item
                          sm={10}
                          xs={10}
                          display="flex"
                          justifyContent={"center"}
                        >
                          {!loading ? (
                            queueStates?.user_queue_status === "Success" ? (
                              <Typography
                                sx={{ p: 1, fontFamily: "Jedi", fontSize: 12 }}
                              >
                                Your task is done!
                              </Typography>
                            ) : queueStates?.user_queue_status === "Failed" ? (
                              <Typography
                                sx={{ p: 1, fontFamily: "Jedi", fontSize: 12 }}
                              >
                                Task failed contact here
                                <Button
                                  onClick={handleOpenModal}
                                  sx={{
                                    background: "#000080",
                                    color: "white",

                                    "&:hover": {
                                      backgroundColor: "#0808bc",
                                      color: "f1f1f1",
                                      "& .MuiSvgIcon-root": {
                                        color: "black", // Change the icon color on hover
                                      },
                                    },
                                    "&:active": {
                                      backgroundColor: "#1254e3",
                                      color: "f1f1f1",
                                      "& .MuiSvgIcon-root": {
                                        color: "black", // Change the icon color on click
                                      },
                                    },
                                  }}
                                >
                                  {
                                    <SupportAgentIcon
                                      sx={{
                                        color: "white !important",
                                        fontSize: 20,
                                      }}
                                    />
                                  }
                                </Button>
                              </Typography>
                            ) : (
                              <Typography
                                sx={{ p: 1, fontFamily: "Jedi", fontSize: 12 }}
                              >
                                {`Your queue number is :${queueStates?.user_queue_number}`}
                              </Typography>
                            )
                          ) : null}
                        </Grid>

                        <Grid
                          item
                          sm={10}
                          xs={10}
                          display="flex"
                          justifyContent="right"
                          px={2}
                          py={1}
                        >
                          <Box>
                            <Button
                              variant="contained"
                              size={"small"}
                              sx={{
                                background: "#000080",
                                color: "white",

                                "&:hover": {
                                  backgroundColor: "#4245ed",
                                  color: "f1f1f1",
                                  "& .MuiSvgIcon-root": {
                                    color: "black", // Change the icon color on hover
                                  },
                                },
                                "&:active": {
                                  backgroundColor: "#1254e3",
                                  color: "f1f1f1",
                                  "& .MuiSvgIcon-root": {
                                    color: "black", // Change the icon color on click
                                  },
                                },
                              }}
                              onClick={getQueueStatus}
                            >
                              {" "}
                              {loading ? (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "white" }}
                                />
                              ) : (
                                <Typography
                                  sx={{
                                    fontFamily: "Jedi",
                                    display: "flex",
                                    direction: "row",
                                    fontSize: 12,
                                  }}
                                >
                                  Refresh
                                  {
                                    <RefreshIcon
                                      sx={{
                                        color: "white !important",
                                        fontSize: 18,
                                      }}
                                    />
                                  }
                                </Typography>
                              )}
                            </Button>
                          </Box>
                        </Grid>
                      </Box>

                      <Divider />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <Grid
                      container
                      className={"custom-scroll"}
                      sx={{
                        // background: " rgba(109, 248, 254, 0.6)",
                        borderRadius: "20px",
                        minHeight: "15vh",
                        maxHeight: "68vh",
                        pt: 2,
                        // pb: "15px",
                        px: 2,
                        overflowY: "scroll",
                      }}
                    >
                      {historyState?.map((data: any) => (
                        <Box
                          minHeight={55}
                          maxHeight={55}
                          minWidth={"100%"}
                          sx={{
                            background: " rgba(109, 248, 254, 0.7)",
                            display: "flex",
                            direction: "row",
                            borderRadius: 2,
                            color: "white",
                            mt: 1,
                          }}
                        >
                          <Grid
                            item
                            sm={4}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Typography
                              sx={{ p: 1, fontFamily: "Jedi", fontSize: 12 }}
                            >
                              {moment(data.bornAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            display="flex"
                            justifyContent="right"
                            px={2}
                            py={1}
                          >
                            <Box>
                              <Button
                                sx={{
                                  color: "white",
                                  background: "green",
                                  fontSize: 18,
                                  borderRadius: 10,
                                  mr: 0,
                                  // mt: -1,
                                  border: "1px solid #ffff",
                                  "&:hover": {
                                    background: "rgba(109, 258, 264, 0.6)",
                                    color: "black",
                                  },
                                }}
                                href={`${API.ledger}/transactions/${data?.txnHash}`}
                                target={"_blanK"}
                              >
                                <Typography
                                  sx={{ fontFamily: "Jedi", fontSize: 14 }}
                                >
                                  {"Explorer "}
                                </Typography>

                                <OpenInNewIcon fontSize="small" />
                              </Button>
                            </Box>
                          </Grid>
                        </Box>
                      ))}
                      <Divider />
                    </Grid>
                  </TabPanel>
                </Box>
              </Box>
            )}
          </>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "6px",
          }}
        >
          <form onSubmit={sendEmail}>
            <Box sx={{ pb: 4 }} display={"flex"} justifyContent={"center"}>
              <Typography variant="h4" sx={{ fontFamily: "Arial, sans-serif" }}>
                Lux Lions Support
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: 18, fontFamily: "Arial, sans-serif", pb: 1 }}
              >
                Contact Information
              </Typography>

              <TextField
                label="Email or Contact Number"
                variant="outlined"
                required
                fullWidth
                value={contact}
                onChange={handleContactInfo}
                style={{ marginBottom: "1rem" }}
              />
            </Box>
            <TextField
              multiline
              placeholder="Please describe your issue here"
              variant="outlined"
              fullWidth
              required
              rows={4}
              maxRows={5}
              value={message}
              onChange={handleMessageChange}
              style={{ marginBottom: "1rem" }}
            />
            <Box sx={{ pb: 2 }}>
              <Typography
                sx={{
                  color: "red",
                  fontSize: 18,
                  fontFamily: "Arial, sans-serif",
                }}
              >
                Please describe your issue and provide contact information.
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  background: "#000080",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#4245ed",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  },
                  "&:active": {
                    backgroundColor: "#1254e3",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  },
                }}
              >
                Send
              </Button>
            </Box>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Transactions;
