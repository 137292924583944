import React, { useState } from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { CampaignType } from "../../../../types/campaignTypes";
import Badges from "../../../Badges/Badges";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BurnablesUpdate from "./Burnables";
import Trait from "./Traits";
import Collections from "./Collections";
import Campaigns from "./Campaign";
import { Collection } from "../../../../types/collectiontype";
import TargetMintings from "./TargetMintings";
import TraitContentsUpdate from "./TraitContents";
import CampaignUpdateRewardBag from "./CampaignRewardBag";
import CollectionsRewardBag from "./CollectionsRewardBag";
import BurnablesUpdateRewardBag from "./BurnablesRewardBag";

interface campaignsPropI {
  campaignState: CampaignType;
}

const Single = ({ campaignState }: campaignsPropI) => {
  const { id } = useParams();
  const location = useLocation();
  const currentRouteName = location.pathname;
  const campaignDataProp = campaignState;
  const [refresh, setRefresh] = React.useState(false);

  //console.log("in single", campaignDataProp);
  return (
    <>
      {!currentRouteName?.includes("rewardbag") ? (
        <>
          {campaignDataProp && (
            <Box>
              <Campaigns
                campaign={campaignDataProp}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              <Collections
                campaign={campaignDataProp}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              <Trait
                campaign={campaignDataProp}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              <TraitContentsUpdate
                campaign={campaignDataProp}
                refresh={refresh}
                setRefresh={setRefresh}
              />
              <BurnablesUpdate campaign={campaignDataProp} />
              <TargetMintings campaign={campaignDataProp} />
            </Box>
          )}
        </>
      ) : currentRouteName?.includes("rewardbag") ? (
        <>
          {campaignDataProp && (
            <Box>
              <CampaignUpdateRewardBag
                campaign={campaignDataProp}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              <CollectionsRewardBag
                campaign={campaignDataProp}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              <BurnablesUpdateRewardBag campaign={campaignDataProp} />
              {/* <Trait
                campaign={campaignDataProp}
                setRefresh={setRefresh}
                refresh={refresh}
              />
              <TraitContentsUpdate
                campaign={campaignDataProp}
                refresh={refresh}
                setRefresh={setRefresh}
              />
              <BurnablesUpdate campaign={campaignDataProp} />
              <TargetMintings campaign={campaignDataProp} /> */}
            </Box>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Single;
