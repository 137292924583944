import { Box, Typography } from "@mui/material";
import NoNftFound from "../../../BredingComp/NoNftFound/NoNftFound";
import FemaleCarouselFunc from "../FemaleCarousalComp/FemaleCarosalComp";
interface chooseFemaleNftPropsI {
  isScreenLessThan1725px: boolean;
  areFemaleNfts: boolean;
  nfts: any;
}

const ChooseFemaleNft = ({
  isScreenLessThan1725px,
  areFemaleNfts,
  nfts,
}: chooseFemaleNftPropsI) => {
  const containerWidth = isScreenLessThan1725px ? "200px" : "500px";

  const textFontSize = isScreenLessThan1725px ? 20 : 28;
  const femaleCarouselWidth =
    nfts?.GroupB?.length || 1 > 2
      ? isScreenLessThan1725px
        ? 225
        : 325
      : "auto";

  return (
    <Box
      sx={{
        minWidth: containerWidth,
        boxShadow: "rgb(0 0 0 / 40%)1px 3px 40px 27px",
        background: "rgb(0 0 0 / 35%)",

        zIndex: 99,
      }}
      justifyContent="center"
      mr={isScreenLessThan1725px ? 0 : 15}
      mt={isScreenLessThan1725px ? -8 : -5}
    >
      <Box
        textAlign="center"
        sx={{
          background: "rgba(70, 70, 70, 0.8)",
          border: "solid 2px white",
          borderRadius: "10px",
          zIndex: 99,
          mb: isScreenLessThan1725px ? 0 : 12,
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          // width: "200px",
        }}
      >
        <Typography
          sx={{
            fontSize: textFontSize,
            fontWeight: 600,
            color: "white",
            pr: 1,
            zIndex: 99,
          }}
        >
          CHOOSE A
        </Typography>
        <Typography
          sx={{
            fontSize: textFontSize,
            fontWeight: 600,
            color: "#FF0000",
            zIndex: 99,
          }}
        >
          NFT
        </Typography>
      </Box>
      {areFemaleNfts ? (
        <Box
          width={femaleCarouselWidth}
          sx={{
            boxShadow: "rgb(0 0 0 / 40%)1px 3px 40px 27px",
            background: "rgb(0 0 0 / 35%)",
            mt: isScreenLessThan1725px ? 1 : 2,
            // mr: isScreenLessThan1725px ? 0 : 1,
          }}
          justifyContent="center"
          ml={isScreenLessThan1725px ? 0 : 10}
        >
          <FemaleCarouselFunc />
        </Box>
      ) : (
        <NoNftFound gender="female" />
      )}
    </Box>
  );
};

export default ChooseFemaleNft;
