import { XummPkce, ResolvedFlow } from "xumm-oauth2-pkce";
import { useDispatch } from "react-redux";
import { login } from "../../redux/LoginReducers/LoginReducers";
import { setLoading } from "../../redux/LoaderReducer/LoaderReducer";
import { Box, Button } from "@mui/material";
import LoginButtonImg from "../../assets/HomeAssets/login.png";
import { useNavigate, redirect } from "react-router-dom";
import { axiosRequest } from "../api/api";
import { setNFT } from "../../redux/NFTReducer/NFTReducer";
import React, { useEffect, useState } from "react";

import { setApiHit } from "../../redux/LoaderReducer/LoaderReducer";

export const XUMM_JWKS_URL = "https://oauth2.xumm.app/certs";

const XUMM_API_KEY: any = process.env.REACT_APP_XUMM_API_KEY;

const xumm = new XummPkce(XUMM_API_KEY);

const LoginComp = () => {
  let mblToken = localStorage.getItem("mbltoken");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function XummLogin() {
    if (xumm) {
      xumm.authorize()?.then((response) => {
        dispatch(login(response));
        const currentDate = new Date(); // Get current date and time
        const next23Hours = new Date(
          currentDate.getTime() + 6 * 60 * 60 * 1000
        );

        // let next23Hours = new Date(currentDate.getTime() + 1 * 60 * 1000);
        localStorage.setItem("expire", next23Hours.toISOString());
        if (response) {
          // console.log("xum wallet", response);

          dispatch(setApiHit(true));

          localStorage.setItem("token", response.jwt);
          localStorage.setItem("accnum", response.me.account);
        }
      });
    }

    // dispatch(login("data"));
  }

  return (
    <Box>
      <Button
        variant="contained"
        sx={{
          borderRadius: "5px",
          color: "white",
          background: "#000000",
          mt: -20,
          padding: "unset",
          "&:hover": {
            background: "black",
            border: "1px solid #6AEFFD",
          },
          width: "250px",
        }}
        onClick={XummLogin}
      >
        <img src={`${LoginButtonImg}`} width="90%" />
      </Button>
    </Box>
  );
};
export default LoginComp;
