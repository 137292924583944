import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import TabPanel from "../../../TabPanels/TabPanels";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Tab,
  Tabs,
  Skeleton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { breedRequestBody } from "../DesignGrids";
import styleGrids from "./DesignGridsPanels.module.css";
import Cross from "../../../../assets/cross.png";
import Transparent from "../../../../assets/tr-image.png";

import { Traits } from "../../../../types/traitsType";
import { TraitContents } from "../../../../types/traitContent";
import { Base_Url } from "../../../api/api";
import { useSelector } from "react-redux";

const crossImg = [{ key: 1, img: Cross, name: "none" }];

interface triatcontnet {
  traitsContentInf: TraitContents;
}
interface RightgridPanelI {
  setCanvasObject: React.Dispatch<React.SetStateAction<breedRequestBody>>;
  canvasObject: breedRequestBody;
  nameObject: breedRequestBody;
  setNameObject: React.Dispatch<React.SetStateAction<breedRequestBody>>;
  traitsObject: Traits[] | undefined;
}

function LeftGridPanel(props: RightgridPanelI) {
  const traits = props.traitsObject;
  const traitsp: any = [];

  traits &&
    traits?.map((d: any, i) => {
      // const images = d.traitContent?.map((tc: any) => tc);
      let reversedArr = d.traitContent;

      const images = reversedArr?.map((tc: any) => ({
        ...tc,
        trait_name: d.title, // Add the trait_name here
        indexNumber: d.layerNumber,
        required: d.required,
        image: `${Base_Url}/${tc.imageName}`,
      }));

      // Check if the first trait's `required` is false
      if (images.length > 0 && images[0].required === false) {
        // Hardcoded image data
        const hardcodedImage = {
          // Replace with your hardcoded image properties
          image: Transparent,
          imageName: Transparent,
          trait_name: "transparent",
          indexNumber: 0,
          required: false,
        };

        // Add the hardcoded image as the first element of the images array
        images.unshift(hardcodedImage);
      }

      traitsp.push({
        id: d.id,
        title: d.title,
        layerNumber: d.layerNumber,
        order: d.order,
        traitContent: {
          images: images,
        },
      });
    });

  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  const [activeBorder, setActiveBorder] = useState<number>(0);
  const [leftPanelValue, setLeftPanelValue] = useState<number>(0);
  const [canvasData, setCanvasData] = useState<any>([]);
  const handleChangeLeftPanel = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setLeftPanelValue(newValue);
  };

  const handleLayer = (item: any, ImageIndex: number, layerNumber: number) => {
    const updatedDataArray = [...canvasData];

    updatedDataArray[layerNumber - 1] = item;

    setCanvasData(updatedDataArray);

    const newCharacterObject = structuredClone(props.canvasObject);

    newCharacterObject.leftChildTraits.layerImage = updatedDataArray;

    props.setCanvasObject(newCharacterObject);

    // For border on the chosen image
    setActiveBorder(ImageIndex);
  };
  const specificImageName = "uploads/transparent/transparent.png";

  return (
    <Box
      mt={1}
      // ml={2}
      sx={{
        background: "rgba(63, 63, 63, 0.9)",
        // height: "90%",
        borderRadius: "10px",
        pb: "4px",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          value={leftPanelValue}
          onChange={handleChangeLeftPanel}
          aria-label="basic tabs example"
          TabScrollButtonProps={{
            sx: { color: "white" },
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor:
                campaignsSelector?.buttonColor != null
                  ? campaignsSelector?.buttonColor
                  : "#6DF8FE",
            },
          }}
          sx={{
            "& button.Mui-selected": {
              color:
                campaignsSelector?.buttonColor != null
                  ? campaignsSelector?.buttonColor
                  : "#6DF8FE",
            },
          }}
        >
          {traitsp?.map((t: any, arrayIndex: number) => {
            return (
              <Tab
                label={t.title}
                sx={{
                  color: "white",
                  borderRight: `4px solid ${
                    campaignsSelector?.backgroundColor != null
                      ? campaignsSelector?.backgroundColor
                      : "rgba(109, 248, 254, 0.6)"
                  }`,
                  background: "#003548",
                }}
                key={arrayIndex}
              />
            );
          })}
        </Tabs>
      </Box>
      {/* ///////////////////////////////////////////  Background   ///////////////////////////////////////////////// */}
      {traitsp &&
        traitsp?.map((items: any, arrayIndex: number) => {
          return (
            <>
              <TabPanel
                value={leftPanelValue}
                index={arrayIndex}
                key={arrayIndex}
              >
                <Box
                  sx={{ background: "background: rgba(0, 0, 0, 0.5)" }}
                  minHeight="55vh"
                >
                  {items && items?.traitContent?.images ? (
                    <Grid
                      container
                      spacing={{ sm: 2 }}
                      columns={{ sm: 9 }}
                      pl={2}
                      minHeight="30vh"
                      maxHeight="30vh"
                    >
                      {items?.traitContent?.images ? (
                        items?.traitContent?.images.map(
                          (d: any, imageIndex: number) => {
                            return (
                              <Grid item sm={3} key={imageIndex}>
                                {activeBorder == imageIndex ? (
                                  <Box
                                    width="6vw"
                                    height="10vh"
                                    className={styleGrids.grid}
                                    key={imageIndex}
                                    onClick={() => {
                                      handleLayer(
                                        d,
                                        imageIndex,
                                        items.layerNumber
                                      );
                                    }}
                                    sx={{
                                      border: `2px solid ${campaignsSelector?.buttonColor}`,
                                    }}
                                  >
                                    {d?.trait_name === "transparent" ? (
                                      <img
                                        src={Cross}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        key={imageIndex}
                                      />
                                    ) : (
                                      <img
                                        src={d?.image}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        loading="lazy"
                                        key={imageIndex}
                                      />
                                    )}
                                  </Box>
                                ) : (
                                  <Box
                                    width="6vw"
                                    height="10vh"
                                    className={styleGrids.grid}
                                    key={imageIndex}
                                    onClick={() => {
                                      handleLayer(
                                        d,
                                        imageIndex,
                                        items.layerNumber
                                      );
                                    }}
                                  >
                                    {d?.trait_name === "transparent" ? (
                                      <img
                                        src={Cross}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        key={imageIndex}
                                      />
                                    ) : (
                                      <img
                                        src={d?.image}
                                        alt=""
                                        style={{ width: "6vw", height: "10vh" }}
                                        loading="lazy"
                                        key={imageIndex}
                                      />
                                    )}
                                  </Box>
                                )}
                              </Grid>
                            );
                          }
                        )
                      ) : (
                        <p>No images data avaialable</p>
                      )}
                    </Grid>
                  ) : (
                    <div>
                      <p>No images data available</p>
                    </div>
                  )}
                </Box>
              </TabPanel>
            </>
          );
        })}
    </Box>
  );
}

export default LeftGridPanel;
export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    skin: string;
    eye: string;
    eyewear: string;
    mouth: string;
    clothing: string;
    eyes: string;
    headgear: string;
    accesories: string;
  };
}
