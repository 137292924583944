import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";

import Header2 from "../../assets/BreedingAssets/heading2.png";
import LogoutComp from "../../Components/Logout/Logout";
import Logo from "../../assets/BreedingAssets/logo.png";
import BreedingBackground from "../../assets/BreedingAssets/BreedingBackground.png";
// import adminbackground from "../../assets/BreedingAssets/adminbackground.jpg";
import RefreshIcon from "@mui/icons-material/Refresh";
import useMediaQuery from "@mui/material/useMediaQuery";
import styleGrids from "./DesignGrids.module.css";
import { useDispatch, useSelector } from "react-redux";
import { API, Base_Url, axiosRequestEmail } from "../../Components/api/api";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { setSnackbar } from "../../redux/Snackbar/SnackbarReducder";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";
import TabPanel from "../../Components/TabPanels/TabPanels";
import { setMale, setFemale } from "../../redux/GenderReducer/GenderReducer";
import io from "socket.io-client";
import {
  Box,
  Button,
  Tabs,
  Link,
  Grid,
  Paper,
  Typography,
  Chip,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Divider,
  Modal,
} from "@mui/material";
import { setPotionId } from "../../redux/PotionReducer/PotionReducer";
import { IsAny } from "@reduxjs/toolkit/dist/tsHelpers";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 800,
  bgcolor: "rgba(109, 220, 254,1)",

  boxShadow: 24,
  p: 4,
};

const historyURL = "api/luxlion/child-nfts";

const token: any = localStorage.getItem("token");

// using for lux lab version 2
const Help = () => {
  const smallScreenCheck = useMediaQuery("(min-width:450px)");
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const [contact, setContact] = useState<any>("");
  const [message, setMessage] = useState<any>("");

  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  const backgroundUrl = Base_Url + `/${campaignsSelector?.background}`;
  const logoUrl = Base_Url + `/${campaignsSelector?.logo}`;

  const [loading, setLoading] = useState<boolean>(false);

  const handleContactInfo = (e: any) => {
    setContact(e.target.value);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleMessageChange = (e: any) => {
    setMessage(e.target.value);
  };

  async function sendEmail() {
    setLoading(true);
    let user = localStorage.getItem("accnum");
    try {
      console.log("Sending email", message);
      setMessage("");
      setContact("");
      setOpenModal(false);
      const response: any = await axiosRequestEmail(
        "post",
        "api/luxlion/support",
        {
          user_email: contact,
          user_account: user,
          user_query: message,
        }
      );
      setLoading(false);

      // setLoaderStateHistory(false);
      if (response.data.status === true) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Email sent, we will contact you shortly",
          })
        );
      }
    } catch (err: any) {
      setLoading(false);

      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Something went wrong Please try again!",
        })
      );
      // }
      // setLoaderStateHistory(false);
    }
  }

  return (
    <>
      <div>
        {campaignsSelector != null ? (
          <img
            src={backgroundUrl}
            alt=""
            style={{ width: "100%", height: "100vh" }}
          />
        ) : (
          <img
            src={BreedingBackground}
            alt=""
            style={{ width: "100%", height: "100vh" }}
          />
        )}

        <div
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
          }}
        >
          <>
            <Grid container spacing={2}>
              <Grid
                item
                sm={3}
                lg={3}
                md={3}
                xs={12}
                sx={{ paddingTop: 4, display: "flex" }}
                justifyContent="center"
              >
                <Box sx={{ maxWidth: 150, paddingTop: 3 }}>
                  {campaignsSelector != null ? (
                    <img
                      src={`${logoUrl}`}
                      alt=""
                      width="100%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/breeding/${campaignsSelector.campaign_id}`);
                      }}
                    />
                  ) : (
                    <img
                      src={`${Logo}`}
                      alt=""
                      width="100%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/campaigns`);
                      }}
                    />
                  )}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sm={6}
                sx={{ ml: 0 }}
                justifyContent="right"
              >
                <Box
                  width="80%"
                  borderRadius="8px"
                  sx={{
                    paddingInline: 2,
                    mt: 2,
                    pt: 1,
                    pb: 1,
                    border: "1px solid #6DF8FE",
                    background: "rgba(70, 70, 70, 0.8);",
                    display: "flex",
                    justifyContent: "center",
                    ml: 6,
                    mb: 4,
                    color: "white",
                    zInde4x: 99,
                    position: "relative",
                    // Use "auto" for scroll only when content exceeds the box height
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    Lux Lion Support
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                sm={3}
                sx={{ mt: 3, display: "flex" }}
                justifyContent="center"
              >
                <Box sx={{ textAlign: "right" }}>
                  <LogoutComp />
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ paddingInline: 12, pt: 1 }}>
              <Box
                sx={{
                  width: "100%",
                  background: "rgba(70, 70, 70, 0.8);",
                  borderRadius: 5,
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    backgroundColor: "rgba(62, 63, 63, 0.4)",
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                    fontWeight: 600,
                  }}
                >
                  <Paper
                    sx={{
                      height: "80vh",
                      overflowY: "auto",
                      background: "rgba(70, 70, 70, 0.5);",
                    }}
                  >
                    {" "}
                    <Box sx={{ p: 4, color: "white" }}>
                      <Typography variant="h5" gutterBottom>
                        Email Support
                      </Typography>
                      <Typography variant="body1">
                        Welcome to Lux Lions Support service. We're here to
                        assist you with any questions, concerns, or feedback you
                        might have. Our dedicated support team is committed to
                        providing timely and effective assistance via email.
                      </Typography>

                      <Divider style={{ margin: "20px 0" }} />

                      <Typography variant="body1" gutterBottom>
                        Please choose the appropriate contact category below
                        contact us here{" "}
                        <Button
                          onClick={handleOpenModal}
                          size={"small"}
                          sx={{
                            background: "#000080",
                            color: "white",

                            "&:hover": {
                              backgroundColor: "#0808bc",
                              color: "f1f1f1",
                              "& .MuiSvgIcon-root": {
                                color: "black", // Change the icon color on hover
                              },
                            },
                            "&:active": {
                              backgroundColor: "#1254e3",
                              color: "f1f1f1",
                              "& .MuiSvgIcon-root": {
                                color: "black", // Change the icon color on click
                              },
                            },
                          }}
                        >
                          {
                            <SupportAgentIcon
                              sx={{
                                color: "white !important",
                              }}
                            />
                          }
                        </Button>
                      </Typography>

                      <ul>
                        <li>
                          <Typography variant="body1">
                            <strong>General Inquiries:</strong> For general
                            questions, information about our platform, or any
                            non-technical inquiries,
                          </Typography>
                        </li>
                        {/* <li>
                          <Typography variant="body1">
                            <strong>Technical Support:</strong> If you're
                            experiencing technical issues, encountering errors,
                            or need assistance with your account or
                            transactions,
                          </Typography>
                        </li> */}
                        <li>
                          <Typography variant="body1">
                            <strong>Feedback and Suggestions:</strong> We highly
                            value your feedback and suggestions for improving
                            our platform. Share your thoughts with us at{" "}
                            {/* <Link href="mailto:feedback@nftsite.com">
                              feedback@nftsite.com
                            </Link> */}
                            .
                          </Typography>
                        </li>
                      </ul>

                      <Typography variant="body1" pb={2}>
                        When sending an email, please provide as much detail as
                        possible to help us understand and address your query
                        efficiently and don't forget to enter you email address
                        or Phone number for our team to contact us.
                      </Typography>
                      <Typography variant="h5" gutterBottom>
                        Contact Us on Social Media
                      </Typography>
                      <Typography variant="body1">
                        Connect with us beyond emails! We're available and
                        actively engaged on various social media platforms.
                        Reach out to us, follow our updates, and drop us a
                        direct message on our official profiles.
                      </Typography>

                      <Divider style={{ margin: "20px 0" }} />

                      <Typography variant="body1" gutterBottom>
                        Twitter: Stay updated with our latest announcements,
                        news, and engage with our community on Twitter. Follow
                        us{" "}
                        <Link
                          href="https://twitter.com/LuxLionsNFT"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          @LuxLionsNFT
                        </Link>{" "}
                        and send us a message or tweet your questions, and we'll
                        assist you promptly.
                      </Typography>

                      {/* <Typography variant="body1" gutterBottom>
                        Instagram: Explore our visual content,
                        behind-the-scenes, and more on Instagram. Follow us{" "}
                        <Link
                          href="https://instagram.com/LuxLionsOfficial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          @LuxLionsOfficial
                        </Link>{" "}
                        and send us a direct message for any queries or concerns
                        you might have.
                      </Typography> */}

                      <Typography variant="body1" gutterBottom>
                        Discord: Join our Discord community to connect with
                        other users, share experiences, and get support from our
                        team. Like our page{" "}
                        <Link
                          href="https://discord.gg/NqMjwZZGfY"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://discord.gg/fyJSbSJdcH
                        </Link>{" "}
                        and drop us a message; we're here to help.
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              </Box>
              v
            </Box>
          </>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "6px",
          }}
        >
          <form onSubmit={sendEmail}>
            <Box sx={{ pb: 4 }} display={"flex"} justifyContent={"center"}>
              <Typography variant="h4" sx={{ fontFamily: "Arial, sans-serif" }}>
                Lux Lions Support
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: 18, fontFamily: "Arial, sans-serif", pb: 1 }}
              >
                Contact Information
              </Typography>

              <TextField
                label="Email or Contact Number"
                variant="outlined"
                required
                fullWidth
                value={contact}
                onChange={handleContactInfo}
                style={{ marginBottom: "1rem" }}
              />
            </Box>
            <TextField
              multiline
              placeholder="Please describe your issue here"
              variant="outlined"
              fullWidth
              required
              rows={4}
              maxRows={5}
              value={message}
              onChange={handleMessageChange}
              style={{ marginBottom: "1rem" }}
            />
            <Box sx={{ pb: 2 }}>
              <Typography
                sx={{
                  color: "red",
                  fontSize: 18,
                  fontFamily: "Arial, sans-serif",
                }}
              >
                Please describe your issue and provide contact information.
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  background: "#000080",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#4245ed",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  },
                  "&:active": {
                    backgroundColor: "#1254e3",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  },
                }}
              >
                Send
              </Button>
            </Box>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default Help;
