import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@mui/material/styles";
import BreedingBackground from "../../../assets/Background/backgroundImageMbl.jpg";
import "./TabPanels.css";
import Canvas from "../../../Components/Admin-Scenes/Canvas/Canvas";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import GlassesIcon from "../../../assets/Icons/Eyewear.svg";
import MouthIcon from "../../../assets/Icons/mouth.png";

import Logo from "../../../assets/BreedingAssets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/Snackbar/SnackbarReducder";
import { MintingObj } from "../CharacterDesignComp/DesignGrids";
import {
  API,
  API_Socket,
  Base_Url,
  axiosRequest,
  axiosRequestAuth,
  axiosRequestLocal,
} from "../../../Components/api/api";
import { setCurentProcessNumber } from "../../../redux/QueueProcess/QueueProcessReducer";
import { setYorProcessNumber } from "../../../redux/QueueProcess/QueueProcessReducer";
import { connectSocket } from "../../../redux/SocketReducer/SocketReducer";
import {
  Button,
  Collapse,
  Paper,
  Grid,
  Modal,
  Box,
  Backdrop,
  Switch,
  FormControlLabel,
  Slide,
  Fade,
  IconButton,
  Checkbox,
  Typography,
  CircularProgress,
} from "@mui/material";

import styleGrids from "./DesignGridsPanels.module.css";
import io from "socket.io-client";
import { setApiHit } from "../../../redux/LoaderReducer/LoaderReducer";
import { setLoader } from "../../../redux/QueueProcess/QueueProcessReducer";
import Cross from "../../../assets/cross.png";
import Transparent from "../../../assets/tr-image.png";

import LogoutComp from "../../Logout/Logout";
import { breedRequestBody } from "../CharacterDesignComp/DesignGrids";
import { setFemale, setMale } from "../../../redux/GenderReducer/GenderReducer";
import { setPotionId } from "../../../redux/PotionReducer/PotionReducer";
import { CampaignType } from "../../../types/campaignTypes";
import { useParams } from "react-router-dom";

const crossImg = [{ key: 1, img: Cross, name: "none" }];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 250,
  bgcolor: "rgba(109, 220, 254,1)",
  p: 4,
};

interface mobileViewPropI {
  campaignState: CampaignType;
}

const TransitionGrid = ({ campaignState }: mobileViewPropI) => {
  const [activeBorder, setActiveBorder] = useState<number>(0);
  const [openLayer, setOpenLayer] = React.useState(false);

  const [canvasData, setCanvasData] = useState<any>([]);
  const [traitsdata, settraitsdata] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState(false);

  const [mintcheck, setMintCheck] = useState<boolean>(false);

  const [modalOpen, setOpenModal] = useState<boolean>(false);

  const [modalOpen2, setOpenModal2] = useState<boolean>(false);

  const [activeHeadGear, setActiveHeadGear] = useState<number>(0);

  const containerRef = React.useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkIfEmpty, setCheckIfEmpty] = useState<boolean>(false);
  const [checkBoxCheck1, setcheckBoxCheck1] = useState(true);
  const [checkBoxCheck2, setcheckBoxCheck2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [uri, setUri] = useState<string>("");
  const maleToken = useSelector((state: any) => state.gender.male);
  const potionToken = useSelector((state: any) => state.potion?.potionId);
  const femaleToken = useSelector((state: any) => state.gender.female);
  let collectionFamily = useSelector((state: any) => state.mint.family);

  let socketio = useSelector((state: any) => state.socketR.socket);
  let colction = useSelector((state: any) => state.mint.collectionName);
  let issuer = useSelector((state: any) => state.mint.issuer);
  let campid = useSelector((state: any) => state.mint.campaignId);
  let gFess = useSelector((state: any) => state.mint.gasFees);
  let taxxons = useSelector((state: any) => state.mint.taxxon);
  let _uri = useSelector((state: any) => state.gender.uri);
  const specificImageName = "uploads/transparent/transparent.png";

  const traitsp: any = [];
  traitsdata &&
    traitsdata?.map((d: any, i) => {
      let reversedArr = d.traitContent;
      const images = reversedArr?.map((tc: any) => ({
        ...tc,
        trait_name: d.title, // Add the trait_name here
        indexNumber: d.layerNumber,
        required: d.required,
        image: `${Base_Url}/${tc.imageName}`,
      }));
      if (images.length > 0 && images[0].required === false) {
        // Hardcoded image data
        const hardcodedImage = {
          // Replace with your hardcoded image properties
          image: Transparent,
          imageName: Transparent,
          trait_name: "transparent",
          indexNumber: 0,
          required: false,
        };

        // Add the hardcoded image as the first element of the images array
        images.unshift(hardcodedImage);
      }
      traitsp.push({
        id: d.id,
        title: d.title,
        layerNumber: d.layerNumber,
        order: d.order,
        traitContent: {
          images: images,
        },
      });
    });

  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  const handleCategoryClick = (categoryId: number) => {
    setSelectedCategory(categoryId);
  };

  const selectedCategoryData = traitsp.find(
    (category: any) => category.id === selectedCategory
  );

  const [characterObjectState, setCharacterObjectState] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      leftChildTraits: {
        layerImage: [],
      },
      uri: "",
    });

  const [characterObjectName, setCharacterObjectName] =
    useState<breedRequestBody>({
      male: {
        tokenId: maleToken,
      },
      female: {
        tokenId: femaleToken,
      },
      potion: {
        tokenId: potionToken,
      },
      leftChildTraits: {
        layerImage: [],
      },
      uri: "",
    });

  const imageWidth = "25px";
  const imageHeight = "35px";

  const [mintingObjectName, setMintingObjectName] = useState<MintingObj>({
    groupA: {
      tokenId: maleToken,
    },
    groupB: {
      tokenId: femaleToken,
    },
    potion: {
      tokenId: potionToken,
    },
    minter: {
      compainid: campid,
      taxxon: taxxons,
      collectionName: colction,
      gasFees: gFess,
      issuer: issuer,
      family: collectionFamily,
    },
    traitContents: characterObjectState?.leftChildTraits?.layerImage,
    campaignId: campaignState?.campaign_id,
    uri: "",
  });

  const updatedMintingObjectName = {
    ...mintingObjectName, // Copy all properties from the current state object
    traitContents: characterObjectState?.leftChildTraits?.layerImage, // Update the Layers property with new value
  };

  const updateUri = (newUri: any) => {
    setMintingObjectName((prevObject) => ({
      ...prevObject,
      uri: newUri,
    }));
  };

  // Compulsory and not compulsory selection of traits

  // API to create NFT handled by Qasim
  async function createNFT() {
    dispatch(setLoader(true));
    setLoading(true);
    try {
      const response: any = await axiosRequestAuth(
        "post",
        API.cubApiURL,
        mintingObjectName,
        undefined
      );
      console.log("RESPONSE I GOT IS: ", response);
      // if (!response) {
      //   // setOpenModal(false);
      //   // dispatch(setLoader(false));
      //   setLoading(false);
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "error",
      //       snackbarMessage: "Something went wrong please try again!",
      //     })
      //   );
      //   return;
      // }
      dispatch(setLoader(false));
      setOpenModal(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);

      console.log("data is Error", err?.response.data.message);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `${err?.response.data.message}`,
        })
      );
      setOpenModal(false);
      dispatch(setLoader(false));
    }
  }
  // get Traits
  const getData = async (id: number | undefined) => {
    try {
      const res = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign/${id}`
      );
      settraitsdata(res.data.campaign.traits);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  // Handle Checks for Select trait which need to be dynamic
  const handleChangeLayer = () => {
    setOpenLayer((prev) => !prev);
  };

  // Handle Check which needs to

  const handleLayer = (item: any, ImageIndex: number, layerNumber: number) => {
    const updatedDataArray = [...canvasData];

    updatedDataArray[layerNumber - 1] = item;

    setCanvasData(updatedDataArray);

    const newCharacterObject = structuredClone(characterObjectState);

    newCharacterObject.leftChildTraits.layerImage = updatedDataArray;
    setCharacterObjectState(newCharacterObject);

    // For border on the chosen image
    setActiveBorder(ImageIndex);
  };

  let updatedTraitContentsArr = [];
  for (let i = 0; i < mintingObjectName?.traitContents.length; i++) {
    if (mintingObjectName?.traitContents[i]?.trait_name === "transparent") {
      // Do Nothing
    } else {
      updatedTraitContentsArr.push(mintingObjectName?.traitContents[i]);
    }
  }
  mintingObjectName.traitContents = updatedTraitContentsArr;

  const socketsReq = (socket: any) => {
    dispatch(connectSocket(socket));

    socket.on("connect", () => {
      console.log("socket Connected");
    });

    socket.on("disconnect", (reason: any) => {
      console.log("socket is disconnected", reason);
    });

    socket.on("burn/signed", (data: any) => {
      setMintCheck(true);
      dispatch(setLoader(true));
      if (data?.didUserSign === false) {
        setMintCheck(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Burn request rejected from Xumm",
          })
        );
        setOpenModal(false);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Burn request accepted from Xumm",
          })
        );
      }
    });
    socket.on("mint/signed", (data: any) => {
      setOpenModal(false);

      if (data?.didUserSign === false) {
        setMintCheck(false);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Mint request rejected from Xumm",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage:
              "Please click Refresh Button to check your status! mobile",
          })
        );
        navigate("/transaction-history");
      }
    });
    socket.on("queue/progress", (data: any) => {
      setMintCheck(false);
      dispatch(setCurentProcessNumber(data.currentlyProcessing));
    });
    socket.on("queue/added", (data: any) => {
      dispatch(setYorProcessNumber(data.newMintQueueNumber));
      dispatch(setCurentProcessNumber(data.currentlyProcessing));
    });
    socket.on("mint/completed", (data: any) => {
      dispatch(
        setSnackbar({
          snackbarOpen: false,
          snackbarType: "info",
          snackbarMessage: ``,
        })
      );
      setOpenModal(false);

      if (data.didFail) {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              "Something went wrong, please contact support@luxlionsnft.com",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage:
              "Please click Refresh Button to check your status!",
          })
        );
      }
      dispatch(setLoader(false));
      dispatch(connectSocket({}));
    });
  };

  const handleCreate = () => {
    if (checkIfEmpty === true) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",

          snackbarMessage: "Please select all required Fileds",
        })
      );
      // setCheckIfEmpty(false);
      return;
    }

    characterObjectName.uri = characterObjectState.uri;

    try {
      const token = localStorage.getItem("token");
      socketio = io(API_Socket, {
        autoConnect: false,
        auth: { token },
      });

      // useDispatch(setSocketConnected());
      socketsReq(socketio);

      socketio.connect();
      setTimeout(() => {
        createNFT();
      }, 1000);
    } catch (e) {
      console.log(e, "error in socket");
    }
    // createNFT();
    // }
  };

  // ------------------useEffect----------------
  useEffect(() => {
    dispatch(setApiHit(false));
    getData(campaignState?.campaign_id);
  }, []);

  useEffect(() => {
    updateUri(_uri);
  }, [_uri]);

  useEffect(() => {
    for (let i = 0; i < campaignState?.traits.length; i++) {
      let campaignRequiredCount = 0;
      let arrayRequiredCount = 0;

      for (let i = 0; i < campaignState?.traits.length; i++) {
        if (campaignState?.traits[i]["required"] === true) {
          campaignRequiredCount++;
        }
        if (
          characterObjectState?.leftChildTraits?.layerImage[i] &&
          characterObjectState?.leftChildTraits?.layerImage[i].required === true
        ) {
          arrayRequiredCount++;
        }
      }

      if (campaignRequiredCount === arrayRequiredCount) {
        setCheckIfEmpty(false);
      } else {
        setCheckIfEmpty(true);
      }
    }

    setMintingObjectName(updatedMintingObjectName);
  }, [characterObjectState?.leftChildTraits?.layerImage]);

  useEffect(() => {
    document.body.style.overflow = "auto";

    getData(campaignState?.campaign_id);
  }, []);

  return (
    <div>
      {/* Image Background */}
      <img
        src={BreedingBackground}
        alt=""
        style={{ width: "100%", height: "100vh" }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                mt: 4,
                justifyContent: "space-between",
                display: "flex",
                px: 4,
              }}
            >
              {/* Logo */}

              <Box>
                <img
                  src={`${Base_Url}/${campaignState.logo}`}
                  alt=""
                  style={{ cursor: "pointer" }}
                  width="60%"
                  onClick={() =>
                    navigate(`/breeding/${campaignState?.campaign_id}`)
                  }
                />
              </Box>
              <LogoutComp />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          display="flex"
          textAlign="center"
        >
          <Grid
            sm={12}
            md={12}
            sx={{ px: 2, mb: -2, display: "flex", justifyContent: "center" }}
          >
            <Grid sm={5}>
              {/* Choose trait box */}
              <Box
                sx={{
                  mx: 0,
                  background: "rgba(63, 63, 63, 0.9)",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  height: "4vh",
                  mt: 4,
                  textAlign: "center",
                  width: 300,
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 18,
                    fontWeight: 600,
                    textShadow: "0px 4px 3px rgba(109, 248, 254, 0.5);",
                  }}
                >
                  CHOOSE YOUR TRAITS
                </Typography>
              </Box>
              {/* Canvas */}
              <Canvas
                selectedTraitsArray={
                  characterObjectState?.leftChildTraits?.layerImage
                }
                setMintingObjectName={mintingObjectName}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* ///////////////////////  Create  ///////////////// */}
        <Box sx={{ mt: 4, justifyContent: "center", display: "flex", mb: 3 }}>
          {/* Buttons  */}

          {/* Handle Create Button */}
          <Button
            variant="contained"
            sx={{
              color: "white",
              background: "rgba(70, 70, 70, 0.8)",
              border: "1px solid #ffff",
              "&:hover": {
                background: "#36454F",
              },
            }}
            onClick={handleCreate}
          >
            {loading === false ? "Create" : <CircularProgress size={20} />}
          </Button>
        </Box>
        {/* ///////////////////////  1st  ///////////////// */}
        <Grid container display="flex" justifyContent="space-between" sm={4}>
          {/* Buttons  */}
          {/* Handle Background button */}
          {traitsp ? (
            traitsp?.map((category: any, arrayIndex: number) => {
              return (
                <Grid item sm={4} xs={4} mb={4}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        color: "white",
                        background: "#003548",

                        // border: "1px solid #ffff",
                        "&:hover": {
                          background: "rgba(109, 258, 264, 0.6)",
                          color: "black",
                        },
                        borderRadius: "0px",
                        p: 0.5,
                        key: { arrayIndex },
                      }}
                      key={category.id}
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      {category.title.substr(0, 4)}
                    </Button>
                  </Box>
                </Grid>
              );
            })
          ) : (
            <Box>No traits are available</Box>
          )}
        </Grid>

        {/* ////////////////////  background ///////////////////////////////////*/}

        <Backdrop
          open={selectedCategory !== null}
          onClick={() => setSelectedCategory(null)}
        >
          <Slide
            direction="up"
            in={selectedCategory !== null}
            container={containerRef.current}
            timeout={{ enter: 500 }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: "20px",
                minHeight: "70vh",
                maxHeight: "70vh",
                px: 2,
                ml: 4,
              }}
            >
              <Grid item sm={12} md={12} lg={12}>
                <Box
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    p: 2,
                    m: 0,
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    spacing={{ sm: 2 }}
                    columns={{ sm: 8 }}
                    className={"custom-scroll"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "rgba(63, 63, 63, 0.9)",
                      borderRadius: "10px",
                      minHeight: "70vh",
                      maxHeight: "70vh",
                      p: 2,
                      border: "solid 8px",
                      borderColor: "rgba(63, 63, 63, 1)",
                      overflowY: "scroll",
                    }}
                  >
                    {selectedCategoryData?.traitContent?.images ? (
                      selectedCategoryData?.traitContent?.images?.map(
                        (image: any, imageIndex: number) => (
                          <Grid
                            item
                            sm={4}
                            key={imageIndex}
                            sx={{ py: 2, px: 1 }}
                          >
                            {activeBorder === imageIndex ? (
                              <Box
                                width="25vw"
                                height="10vh"
                                className={styleGrids.grid}
                                key={imageIndex}
                                onClick={() =>
                                  handleLayer(
                                    image,
                                    imageIndex,
                                    selectedCategoryData.layerNumber
                                  )
                                }
                                sx={{ border: "2px solid #6DF8FE" }}
                              >
                                {image?.trait_name === "transparent" ? (
                                  <img
                                    src={Cross}
                                    alt=""
                                    style={{
                                      width: "25vw",
                                      height: "10vh",
                                    }}
                                    key={imageIndex}
                                  />
                                ) : (
                                  <img
                                    src={image?.image}
                                    alt=""
                                    style={{
                                      width: "25vw",
                                      height: "10vh",
                                    }}
                                    key={imageIndex}
                                  />
                                )}
                              </Box>
                            ) : (
                              <Box
                                width="25vw"
                                height="10vh"
                                className={styleGrids.grid}
                                key={imageIndex}
                                onClick={() =>
                                  handleLayer(
                                    image,
                                    imageIndex,
                                    selectedCategoryData.layerNumber
                                  )
                                }
                              >
                                {image?.trait_name === "transparent" ? (
                                  <img
                                    src={Cross}
                                    alt=""
                                    style={{
                                      width: "25vw",
                                      height: "10vh",
                                    }}
                                    key={imageIndex}
                                  />
                                ) : (
                                  <img
                                    src={image?.image}
                                    alt=""
                                    style={{
                                      width: "25vw",
                                      height: "10vh",
                                    }}
                                    key={imageIndex}
                                  />
                                )}
                              </Box>
                            )}
                          </Grid>
                        )
                      )
                    ) : (
                      <Box>
                        <Typography sx={{ color: "white" }}>
                          No Trait Images are available
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </Grid>{" "}
            </Grid>
          </Slide>
        </Backdrop>

        {/* ////////////////////  Head Gear ///////////////////////////////////*/}
      </div>
      <>
        <Modal open={modalOpen}>
          <Box sx={style}>
            {mintcheck === true ? (
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ fontFamily: "Jedi", fontWeight: 600 }}
              >
                Mint request generated. Awaiting xumm approval!
              </Typography>
            ) : (
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{ fontFamily: "Jedi", fontWeight: 600 }}
              >
                Burn request generated. Awaiting xumm approval!
                {/* Burn request generated on mobile */}
              </Typography>
            )}
          </Box>
        </Modal>
      </>
    </div>
  );
};

export default TransitionGrid;
export interface characterObjectI {
  issuer: string;
  attributes: {
    background: string;
    skin: string;
    eyewear: string;
    mouth: string;
    clothing: string;
    eyes: string;
    headgear: string;
    accesories: string;
  };
}
export interface APIObject {
  groupA: {
    tokenId: string;
  };
  groupB: {
    tokenId: string;
  };
  potion: {
    tokenId: string;
  };
  minter: minterI;
  traitContents: Array<string>;
  uri: string;
}

export interface minterI {
  compainid: number;
  taxxon: number;
  collectionName: string;
  gasFees: number;
  issuer: string;
}
