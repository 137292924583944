import { XummPkce, ResolvedFlow } from "xumm-oauth2-pkce";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/LoginReducers/LoginReducers";
import {
  Typography,
  Box,
  Paper,
  Card,
  Grid,
  Button,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LoginButtonImg from "../../assets/HomeAssets/login.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMale, setFemale } from "../../redux/GenderReducer/GenderReducer";
import { setNFT } from "../../redux/NFTReducer/NFTReducer";
import { setPotionId } from "../../redux/PotionReducer/PotionReducer";
import io from "socket.io-client";
import { Socket } from "socket.io";
import { connectSocket } from "../../redux/SocketReducer/SocketReducer";
import { setApiHit } from "../../redux/LoaderReducer/LoaderReducer";
import { setCampaigns } from "../../redux/CampaignsReducer/CampaignsReducer";
import {
  setCurentProcessNumber,
  setLoader,
  setYorProcessNumber,
} from "../../redux/QueueProcess/QueueProcessReducer";
const XUMM_API_KEY: any = process.env.REACT_APP_XUMM_API_KEY;

const xumm2 = new XummPkce(XUMM_API_KEY);

// used for Lux Lab verion 2
const LogoutComp = () => {
  const location = useLocation();
  const currentRouteName = location.pathname;

  let socketio = useSelector((state: any) => state.socketR.socket);
  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  const accLocal = localStorage.getItem("accnum");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const acc = useSelector((state: any) => state.xumm.account);

  const navigate = useNavigate();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  function XummLogout() {
    xumm2.logout();

    localStorage.clear();
    navigate("/");
    dispatch(logout());
    dispatch(setCampaigns(null));
    dispatch(setApiHit(false));
    dispatch(setMale(""));
    dispatch(setPotionId(""));
    dispatch(setFemale(""));
    dispatch(setNFT({}));
    setAnchorEl(null);
    setOpenMenu(false);
    dispatch(setYorProcessNumber(""));
    dispatch(setCurentProcessNumber(""));

    socketio.disconnect();
    dispatch(connectSocket({}));
    dispatch(setLoader(false));
  }

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={handleOpenMenu}
          size="small"
          sx={{
            color: "white",
            background: "#1b1b1b35",

            border: `1px solid ${
              campaignsSelector?.buttonColor != null
                ? campaignsSelector?.buttonColor
                : "#6DF8FE"
            }`,
            "&:hover": {
              background:
                campaignsSelector?.backgroundColor != null
                  ? campaignsSelector?.backgroundColor
                  : "rgba(109, 258, 264, 0.6)",
              color: "white",
            },
          }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {!accLocal ? (
            <Typography sx={{ fontFamily: "jedi" }}>accnum </Typography>
          ) : (
            <Typography sx={{ fontFamily: "jedi" }}>
              {accLocal.slice(0, 8)}
            </Typography>
          )}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            // "& .MuiAvatar-root": {
            //   width: 32,
            //   height: 32,
            //   ml: -0.5,
            //   mr: 1,
            // },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleCloseMenu}>
          {!accLocal ? (
            <Typography sx={{ fontFamily: "jedi" }}>accnum </Typography>
          ) : (
            <Typography sx={{ fontFamily: "jedi" }}>{accLocal}</Typography>
          )}
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => navigate("/help")}>Help</MenuItem>

        <Divider />
        <MenuItem onClick={() => navigate("/select-campaign")}>Home</MenuItem>
        {!currentRouteName.includes("rewardbag") && (
          <>
            <Divider />

            <MenuItem onClick={() => navigate("/transaction-history")}>
              History
            </MenuItem>
          </>
        )}
        {currentRouteName.includes("rewardbag") && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                navigate("/reward");
              }}
            >
              Claim Rewards
            </MenuItem>
          </>
        )}
        {/* ////////////////////////// adding new button  ///////////////// */}
        {/* {!currentRouteName.includes("rewardbag") && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                navigate("/campaigns/rewardbag");
              }}
            >
              Rewards Packs
            </MenuItem>
          </>
        )}
        {currentRouteName.includes("rewardbag") && (
          <>
            <Divider />
            <MenuItem onClick={() => navigate("/campaigns")}>Minting</MenuItem>
          </>
        )} */}

        <Divider />

        <MenuItem onClick={XummLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default LogoutComp;
