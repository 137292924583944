import DesginGrids from "../../Components/Admin-Scenes/CharacterDesignComp/DesignGrids";
import MobilView from "../../Components/Admin-Scenes/CharacterDesignMobileView/CharacterDesignMobileView";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useSelector } from "react-redux";

const CharacterDesign = () => {
  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");
  return (
    <>
      {mobileViewCheck && campaignsSelector ? (
        <DesginGrids campaignState={campaignsSelector} />
      ) : !mobileViewCheck && campaignsSelector ? (
        <MobilView campaignState={campaignsSelector} />
      ) : null}
    </>
  );
};

export default CharacterDesign;
